export default function consoleText (text) {
  const underscore = document.getElementsByTagName('h1')[1]
  const target = document.getElementsByTagName('h1')[0]

  let letterCount = 1
  let x = 1

  let visible = true
  let waiting = false

  window.setInterval(function() {
    if (letterCount === 0 && waiting === false) {
      waiting = true
      target.innerHTML = text.substring(0, letterCount)

      window.setTimeout(function() {
        x = 1
        letterCount += x
        waiting = false
      }, 1000)
    } else if (letterCount === text.length + 1 && waiting === false) {
      waiting = true

      window.setTimeout(function() {
        x = -1
        letterCount += x
        waiting = false
      }, 1000)
    } else if (waiting === false) {
      target.innerHTML = text.substring(0, letterCount)
      letterCount += x
    }
  }, 225)

  window.setInterval(function() {
    if (visible === true) {
      underscore.setAttribute('style', 'display: none')
      visible = false
    } else {
      underscore.setAttribute('style', '')
      visible = true
    }
  }, 500)
}
