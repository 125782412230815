import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import skullUrl from 'url:../assets/skull.glb'

let renderer
let camera
let scene

window.addEventListener( 'resize', onWindowResize, false )

function init () {
  renderer = new THREE.WebGLRenderer({antialias: true})
  renderer.setSize(window.innerWidth, window.innerHeight)
  document.body.appendChild(renderer.domElement)

  camera = new THREE.PerspectiveCamera(30, 800 / 600, 1, 10000)
  camera.position.set(30, 10, 70)
  camera.up.set(0, 1, 0)
  camera.lookAt(new THREE.Vector3(0, 0, 0))
  camera.aspect = window.innerWidth / window.innerHeight
  camera.updateProjectionMatrix()

  scene = new THREE.Scene();

  let ambientLight = new THREE.AmbientLight( 0x404040 )
  scene.add(ambientLight)

  let directionalLight = new THREE.DirectionalLight(0xffffff, 0.8)
  scene.add(directionalLight)

  let light = new THREE.PointLight(0xff0000, 1, 100)
  light.position.set(50, 50, 50)
  scene.add(light)

  const loader = new GLTFLoader()

  const objs = []
  loader.load(skullUrl, gltf => {
    gltf.scene.scale.set(3, 3, 3)

    scene.add(gltf.scene)
    objs.push(gltf.scene)
  });

  (function animate() {
      objs.forEach(obj => {
        obj.rotation.x += 0.01
        obj.rotation.y -= 0.02
      })
      renderer.render(scene, camera)
      requestAnimationFrame(animate)
  })()
}

function onWindowResize(){
  camera.aspect = window.innerWidth / window.innerHeight
  camera.updateProjectionMatrix()

  renderer.setSize(window.innerWidth, window.innerHeight)
}

export default init
